import React from 'react'
import { red, blue, green, orange } from "@material-ui/core/colors";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import opencc from 'node-opencc'
import DemoImage1 from '../images/demo1.png'
import DemoImage2 from '../images/demo2.png'
import DemoImage3 from '../images/demo3.png'
import DemoImage4 from '../images/demo4.png'


const startedText = '知道了'
const descriptions = [
  {
    image: DemoImage1,
    color: red,
    title: '基本導航',
    subTitle: '全屏幕顯示，前、後頁，直接轉頁。亦可利用鍵盤左、右方向鍵，或觸屏左右掃，往前、後頁。'
  },
  {
    image: DemoImage2,
    color: blue,
    title: '圖片縮放',
    subTitle: '點擊圖片，可放大、縮少、平移，也可瀏覽前、後圖片'
  },
  {
    image: DemoImage3,
    color: green,
    title: '聲音播放',
    subTitle: '點擊聲音圖示，可播放、暫停音檔'
  },
  {
    image: DemoImage4,
    color: orange,
    title: '聯絡我們',
    subTitle: '如需協助，請跟我們聯絡：kt2020.crlls@gmail.com'
  }
]

const DemoCarousel = ({ open, onClose, onStart, smallScreen, useSimplifiedChinese }) => {
  return (
    <AutoRotatingCarousel
      label={useSimplifiedChinese ? opencc.traditionalToSimplified(startedText) : startedText}
      open={open}
      onClose={onClose}
      onStart={onStart}
      autoplay={true}
      mobile={smallScreen}
      style={{ position: "absolute" }}
      interval={5000}
    >
      {
        descriptions.map((demo, index) => <Slide
          key={index}
          media={<img src={demo.image} width={400} alt='Demo screen shots'/>}
          mediaBackgroundStyle={{ backgroundColor: demo.color[400] }}
          style={{ backgroundColor: demo.color[600] }}
          title={useSimplifiedChinese ? opencc.traditionalToSimplified(demo.title) : demo.title}
          subtitle={useSimplifiedChinese ? opencc.traditionalToSimplified(demo.subTitle) : demo.subTitle}
        />)
      }
      {/*<Slide
        media={
          <img src={DemoImage1} width={400} alt='Navigation'/>
        }
        mediaBackgroundStyle={{ backgroundColor: red[400] }}
        style={{ backgroundColor: red[600] }}
        title={useSimplifiedChinese ? descriptions[0].title}
        subTitle={descriptions[0].subTitle}
      />
      <Slide
        media={
          <img src={DemoImage2} width={400} alt='Zoom in/out pictures'/>
        }
        mediaBackgroundStyle={{ backgroundColor: blue[400] }}
        style={{ backgroundColor: blue[600] }}
        title={descriptions[1].title}
        subTitle={descriptions[1].subTitle}
      />
      <Slide
        media={
          <img src={DemoImage3} width={400} alt='Play sound'/>
        }
        mediaBackgroundStyle={{ backgroundColor: green[400] }}
        style={{ backgroundColor: green[600] }}
        title={descriptions[2].title}
        subTitle={descriptions[2].subTitle}
      />*/}
    </AutoRotatingCarousel>
  );
};

export default DemoCarousel