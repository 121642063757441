import React from 'react'
import { Popover, IconButton } from '@material-ui/core';
import { Deck, Slide, Heading, Image, Text, UnorderedList, ListItem, Markdown, Progress, FlexBox, Box, FullScreen, OrderedList, Appear } from './spectacle'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import grey from '@material-ui/core/colors/grey';
import Lightbox from 'lightbox-react';
import AudioPlayer from 'react-h5-audio-player';
import { isIOS } from 'react-device-detect';
import 'react-h5-audio-player/lib/styles.css';
import 'lightbox-react/style.css';
import './slide.css';
import opencc from 'node-opencc'

const transition = {
  from: {
    position: 'fixed',
    opacity: '0%',
    transition: 'opacity 2s ease-in'
  },
  enter: {
    position: 'fixed',
    opacity: '100%',
    transition: 'opacity 2s ease-in'
  },
  leave: {
    position: 'fixed',
    opacity: '0%',
    transition: 'opacity 2s ease-in'
  }
};

const makeFontSizeList = ({smallScreen, fontSize}) => {
  const increment = 3
  const baseH1 = smallScreen ? 18 : 24;
  return {
    h1: `${baseH1 + fontSize * increment}px`,
    h2: `${baseH1 + fontSize * increment - 2}px`,
    h3: `${baseH1 + fontSize * increment - 4}px`,
    h4: `${baseH1 + fontSize * increment - 6}px`,
    text: `${baseH1 + fontSize * increment - 8}px`
  }
}

const theme = {
  colors: {
    primary: '#000',
    secondary: '#111',
    tertiary: '#eee'
  },
  fontSizes: {
    h1: '32px',
    h2: '28px',
    h3: '24px',
    h4: '20px',
    text: '24px'
  },
  space: [6, 8, 10, 12],
  fonts: {
    heading: 'Noto Serif, DoulosSILW, serif',
    text: 'Noto Serif, DoulosSILW, serif'
  },
  fontWeights: {
    h1: 900,
    h2: 700,
    h3: 400,
    body: 400,
    text: 400
  },
  lineHeights: {
    body: 1.5,
    heading: 1.9
  },
  letterSpacings: ['0.5em', '1.5em'],
  letterSpacing: 1
};

// const themeSmallScreen = {
//   ...theme,
//   fontSizes: {
//     h1: '22px',
//     h2: '20px',
//     h3: '18px',
//     h4: '16px',
//     text: '14px'
//   }
// };

const initialState = {
  imageIndex: 0,
  isLightBoxOpen: false,
  markdown: null,
  slides: null,
  images: null,
  isAudioOpen: false,
  soundPath: null,
  anchorEl: null
}

class ChapterRenderer extends React.Component {
  constructor () {
    super()
    this.deckRef = React.createRef()
    this._handleNavigateBefore = this._handleNavigateBefore.bind(this)
    this._handleNavigateNext = this._handleNavigateNext.bind(this)
    this._handleClick = this._handleClick.bind(this)
    this.state = initialState
  }

  componentDidMount() {
    this._loadContents()
  }

  componentDidUpdate(prevProps, prevState) {
    console.debug(this.deckRef.current)
    if (prevProps.currentChapter !== this.props.currentChapter || prevProps.useSimplifiedChinese !== this.props.useSimplifiedChinese) {
      // changed to a different chapter. Reset to slide #0
      this._loadContents()
    } else if (!prevState.slides && this.state.slides && this.deckRef) {
      // first landed on a chapter. location may still keep a previous "slide=n" parameter. Reset to slide #0
      this.deckRef.current && this.deckRef.current.goToSlide(0)
    }
  }

  _loadContents() {
    this.deckRef && this.deckRef.current && this.deckRef.current.goToSlide(0)
    const { chapterDetails, useSimplifiedChinese } = this.props
    const { path } = chapterDetails
    fetch(path)
    .then((r) => r.text())
    .then(text => useSimplifiedChinese ? opencc.traditionalToSimplified(text) : text)
    .then(markdown  => {
      const images = this._extractImages(markdown)
      const slides = this._splitSlides(markdown)
      this.setState({
        ...initialState,
        slides,
        images
      })
    })
  }

  _convertMathFormulaShorthand(markdown) {
    return markdown && markdown.replace(/\$\$(.*?)\$\$/g, "\n```marksy\nh(Formula, {}, '$1')\n```\n")
  }

  _playSound(sound, currentTarget) {
    console.debug(`playing sound: ${sound}`)
    this.setState({
      soundPath: sound,
      isAudioOpen: true,
      anchorEl: currentTarget
    })
  }

  _handleNavigateBefore(event) {
    console.debug(`Navigate Before`)
    this.deckRef.current.navigateToPrevious()
    // console.debug(event)
    event.stopPropagation()
  }

  _handleNavigateNext(event) {
    console.debug(`Navigate Next`)
    this.deckRef.current.navigateToNext()
    // console.debug(event)
    event.stopPropagation()

  }

  _handleClick(event) {
    console.debug(event.nativeEvent)
    const target = event.nativeEvent.target
    console.debug(target)
    // if (target.nodeName.toLowerCase() === 'button') {
    //   console.debug(`button clicked`)
    //   console.debug(target)
    //   console.debug(target.attributes)
    //   return true
    // }
    if (target.nodeName.toLowerCase() !== 'img') {
      return
    }
    if (target.alt === 'sound' && target.parentNode.href && target.parentNode.href.match(/#/)) {
      console.debug(`sound clicked`)
      const soundPath = target.parentNode.href.split('#')[1]
      soundPath && this._playSound(soundPath, event.currentTarget)
      return
    }
    if (!this.state.images) {
      return
    }
    const src = target.attributes.src.value
    const imageIndex = this.state.images.findIndex(m => m.src === src)
    if (imageIndex < 0) {
      return
    }
    this.setState({
      isLightBoxOpen: true,
      imageIndex
    })
  }

  _extractImages(markdown) {
    if (!markdown) {
      return null
    }
    const pattern = /^!\[(.*?)\]\((.*?)\)/mg
    const matches = markdown.match(pattern)
    if (!matches) {
      return null
    }
    const images = matches.map(m => {
      const parts = /^!\[(.*?)\]\((.*?)\)/.exec(m)
      return {
        caption: parts[1],
        src: parts[2]
      }
    })
    return images
  }

  _splitSlides(markdown) {
    if (!markdown) {
      return null
    }
    return markdown.split(/^---$/m)
  }

  _isQASlide(markdown) {
    if (!markdown) {
      return null
    }
    const lines = markdown.replace(/^\s+|\s+$/g, '').split(/\s*[\n\r]+\s*/g)
    return !lines.find(line => {
      // allow only headings, images and questions/answers on an Q&A slide
      const notQALine = !line.match(/^#\s+.+/) && !line.match(/^%%.+%%.+%%$/) && !line.match(/^!(\[.*\])\(.*\)$/)
      return notQALine
    })
  }

  render() {
    const { images, imageIndex, isLightBoxOpen, slides, isAudioOpen, anchorEl, soundPath } = this.state;
    const { fontSize, smallScreen, chapterDetails } = this.props
    console.debug(`slides = `)
    console.debug(slides)
    return (
      <div className="spectacle-contents" style={{flex:1}} onClick={this._handleClick}>
      {slides && <Deck ref={this.deckRef} theme={{
        ...theme,
        fontSizes: makeFontSizeList({smallScreen, fontSize})
      }} transitionEffect={transition} template={chapterDetails.showNavBar ? ({ slideNumber, numberOfSlides }) => (
        <FlexBox
          justifyContent="space-around"
          backgroundColor='#555'
          width={1}
          zIndex={2000}
          style={{padding: 0, bottom: 0, position: 'fixed'}}
        >
          <FlexBox flex="1" flexDirection="row" justifyContent="space-around" alignItems="center" paddingX={2}>
            {!isIOS && <FullScreen />}
            <div style={{pointerEvents: 'all', cursor: 'pointer'}}>
              <IconButton name="NavigateBefore" onClick={this._handleNavigateBefore}>
                <NavigateBeforeIcon style={{ color: grey[100] }}/>
              </IconButton>
            </div>
            <div style={{pointerEvents: 'all', cursor: 'pointer'}}>
              <IconButton name="NavigateNext" onClick={this._handleNavigateNext}>
                <NavigateNextIcon style={{ color: grey[100] }}/>
              </IconButton>
            </div>
            <Progress />
            <Box marginLeft="2em">
              <Text color='#fff' fontFamily={'sans-serif'} fontSize="16px">{`${slideNumber + 1} / ${numberOfSlides}`}</Text>
            </Box>
          </FlexBox>
        </FlexBox>
      ) : null}>
        {slides.map((slide, index) => {
          if (!this._isQASlide(slide)) {
            return <Markdown key={index} containsSlides>{this._convertMathFormulaShorthand(slide)}</Markdown>
          } else {
            console.debug(`Q&A slide found: ${slide}`)
            const lines = slide.replace(/^\s+|\s+$/g, '').split(/\s*[\n\r]+\s*/g)
            const headings = lines.filter(line => line.match(/^#+\s*(.+)$/))
            const questions = lines.filter(line => line.match(/^%%(.+)%%(.+)%%$/))
            const images = lines.filter(line => line.match(/^!(\[(.*)\])\((.*)\)$/))
            return (<Slide key={index}>
            {headings.map(line => <Heading key={index}>{line.match(/^#+\s*(.+)$/)[1]}</Heading>)}
            {images.map((line, index) => {
              const match = line.match(/^!(\[(.*)\])?\((.*)\)$/)
              return (<Image key={index} src={match[3]} alt={match[2]}/>)
            })}
            {questions && <OrderedList key={index}>
              {questions.map((line, index) => {
                const match = line.match(/^%%(.+)%%(.+)%%$/)
                console.log(match)
                return (<React.Fragment key={index}>
                <ListItem key={index}>{match[1]}</ListItem>
                <Appear elementNum={index} key={index}>
                  <UnorderedList>
                    <ListItem>
                      <Markdown>
                        {this._convertMathFormulaShorthand(match[2])}
                      </Markdown>
                    </ListItem>
                  </UnorderedList>
                </Appear>
                </React.Fragment>)
              })}
              </OrderedList>}
            </Slide>)
          }
        })}
      </Deck>}
      {images && isLightBoxOpen &&
        <Lightbox
          reactModalStyle={{overlay: {zIndex: 2000}}}
          imageCaption={images[imageIndex].caption}
          mainSrc={images[imageIndex].src}
          nextSrc={images[(imageIndex + 1) % images.length].src}
          prevSrc={images[(imageIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => this.setState({ isLightBoxOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              imageIndex: (imageIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              imageIndex: (imageIndex + 1) % images.length,
            })
          }
        />}
        {isAudioOpen && <Popover
          open={isAudioOpen}
          anchorEl={anchorEl}
          onClose={() => this.setState({isAudioOpen: false})}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <AudioPlayer
            showJumpControls={false}
            customAdditionalControls={[]}
            autoPlay
            layout={'horizontal'}
            src={soundPath}
            customVolumeControls={[]}
            style={{width: '400px'}}
          />
        </Popover>}

      </div>
    )
  }
}

export default ChapterRenderer