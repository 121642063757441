import config from '../resources/config'
const { CREDENTIALS_KEY } = config

const createLoginStartAction = () => ({
  type: 'LOGIN_START'
})

const createLoginErrorAction = (error) => ({
  type: 'LOGIN_FAILED',
  payload: {
    error: error
  }
})

const createAuth0LoginSuccessAction = (profile, loggedInUser, accessToken) => ({
  type: 'AUTH0_SUCCESS',
  payload: {
    profile,
    loggedInUser,
    accessToken
  }
})

export const createLogoutAction = () => ({
  type: 'LOGOUT'
})

const startRestoreCredentials = () => ({
  type: 'START_RESTORE_CREDENTIALS'
})

const restoreCredentials = ({ profile, loggedInUser, accessToken }) => ({
  type: 'RESTORE_CREDENTIALS',
  payload: {
    profile, loggedInUser, accessToken
  }
})

export const destryoAuth0Lock = () => ({
  type: 'DESTROY_AUTH0_LOCK_OBJECT'
})


export const loginAuth0 = (lock, options) => {
  return (dispatch, getState) => {
    lock.on('authenticated', authResult => {
      if (!authResult) {
        dispatch(createLoginErrorAction('Cancelled'))
      } else {
        lock.getUserInfo(authResult.accessToken, (error, profile) => {
          if (error) {
            console.debug(error)
            dispatch(createLoginErrorAction('Login failed'))
          } else {
            console.debug(profile);
            const loggedInUser = extractUserId(profile.sub)
            window.localStorage.setItem(CREDENTIALS_KEY, JSON.stringify({profile, accessToken: authResult.accessToken}))
            dispatch(createAuth0LoginSuccessAction(profile, loggedInUser, authResult.accessToken))
            // lock.hide()
          }
        })
      }
    })
    lock.on('authorization_error', err => {
      console.error(`Authentication error`)
      console.error(err)
      dispatch(createLoginErrorAction((err && err.errorDescription) || err.error || 'Sign in error'))
    })
    // lock.show(options);
    dispatch(createLoginStartAction())
  }
}

export const logout = () => {
  window.localStorage.removeItem(CREDENTIALS_KEY)
  return (dispatch, getState) => {
    const lock = getState().login.lock
    lock && lock.logout({
      federated: true,
      returnTo: window.document.location.href
    })
    dispatch(createLogoutAction())
  }
}

export const checkSavedSignIn = () => {
  console.debug('check saved profile from local storage')
  return (dispatch, getState) => {
    dispatch(startRestoreCredentials())
    const credentialsJson = window.localStorage.getItem(CREDENTIALS_KEY)
    try {
      const { profile, accessToken } = JSON.parse(credentialsJson)
      console.debug(`profile restored:  ${JSON.stringify(profile)}`)
      dispatch(restoreCredentials({profile, loggedInUser: extractUserId(profile.sub), accessToken}))
    } catch (error) {
      console.debug(`No credentials found in storage`)
      dispatch(restoreCredentials({profile: null, loggedInUser: null, accessToken: null}))
    }
  }
}

const extractUserId = (tokenSubject) => tokenSubject
