import React, {Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Intro from './Intro'
import ChapterRenderer from './ChapterRenderer'
// import LightBox from './LightBox'

import {
  changeChapter
} from '../actions/chaptersActions'

const mapStateToProps = state => ({
  chapters: state.chapters
})

const mapDispatchToProps = {
  changeChapter
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 100
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'center'
  }
})

class ContentsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }


  render() {
    const { classes, useSimplifiedChinese, bookInfo, fontSize, smallScreen, signedIn, onSignIn, onClickShowTips } = this.props
    const { currentChapter, chapterInfo } = this.props.chapters
    const currentChapterDetails = chapterInfo.find(c => c.code === currentChapter)
    return (
      <React.Fragment>
        {/*!currentChapterDetails.subTitle && <div className={classes.titleDiv}>
          {currentChapter !== 'authors' && <h1>{`${currentChapterDetails && currentChapterDetails.name}`}</h1>}
        </div>*/}
        <div className={classes.content}>
          {currentChapter === 'authors' && <Intro onClickShowTips={onClickShowTips} fontSize={fontSize} smallScreen={smallScreen} bookInfo={bookInfo} signedIn={signedIn} onSignIn={onSignIn}/>}
          {/*currentChapter === 'preface' && <ChapterPreface/>*/}
          {currentChapter && currentChapter.match(/^(chapter|preface|further-reading|references)/i) &&
            <ChapterRenderer
              fontSize={fontSize}
              smallScreen={smallScreen}
              useSimplifiedChinese={useSimplifiedChinese}
              chapterDetails={currentChapterDetails}
              currentChapter={currentChapter}
            />}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContentsContainer));
