const chapterInfo = [{
  name: '首頁',
  code: 'authors',
  showNavBar: false,
  restricted: false
},{
  name: '前言',
  code: 'preface',
  path: './contents/preface.md',
  showNavBar: false,
  restricted: false
},{
  name: '第一章 認識時頻譜（spectrograms）',
  code: 'chapter1',
  subTitle: '第一章 認識時頻譜',
  path: './contents/chapter1.md',
  showNavBar: true,
  restricted: true
}, {
  name: '第二章 WASP基本軟件操作',
  code: 'chapter2',
  subTitle: '第二章 WASP基本軟件操作',
  path: './contents/chapter2.md',
  showNavBar: true,
  restricted: true
}, {
  name: '第三章 普通話的聲母',
  code: 'chapter3',
  subTitle: '第三章 普通話的聲母',
  path: './contents/chapter3.md',
  showNavBar: true,
  restricted: true
}, {
  name: '第四章 普通話的韻母',
  code: 'chapter4',
  subTitle: '第四章 普通話的韻母',
  path: './contents/chapter4.md',
  showNavBar: true,
  restricted: true
}, {
  name: '第五章 普通話的聲調',
  code: 'chapter5',
  subTitle: '第五章 普通話的聲調',
  path: './contents/chapter5.md',
  showNavBar: true,
  restricted: true
}, {
  name: '第六章 常見臨床兒童語音障礙評估難點',
  code: 'chapter6',
  subTitle: '第六章 常見臨床兒童語音障礙評估難點',
  path: './contents/chapter6.md',
  showNavBar: true,
  restricted: true
}, {
  name: '延伸閱讀',
  code: 'further-reading',
  path: './contents/further-reading.md',
  showNavBar: false,
  restricted: false
}, {
  name: '參考書目',
  path: './contents/references.md',
  code: 'references',
  showNavBar: false,
  restricted: false
}]

export default chapterInfo