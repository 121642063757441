const productionEnv = process.env.REACT_APP_BUILD === 'PROD'
const uatEnv = process.env.REACT_APP_BUILD === 'UAT'
const devEnv = !productionEnv && !uatEnv

const CREDENTIALS_KEY = 'eduhk.putonghua.credentials'
const AGREEMENT_KEY = 'eduhk.putonghua.agreement'
const MESSAGES_KEY = 'eduhk.putonghua.messages'
const SETTINGS_KEY = 'eduhk.putonghua.settings'

// const auth0Config = {
//   clientId: "BhugFFBREnEThM6dLYq2GL3z5DeP3Ixy",
//   domain: "eduhk-putonghua.auth0.com"
// }

const auth0Config = {
  clientId: "YNFDuitcPjGyakbPKRC32yZ20QL1dEe2",
  domain: "kt2020.auth0.com"
}

const config = {
  productionEnv,
  uatEnv,
  devEnv,
  auth0Config,
  CREDENTIALS_KEY,
  AGREEMENT_KEY,
  MESSAGES_KEY,
  SETTINGS_KEY
}

export default config
