const initialState = {
  lock: null,
  loginStarted: false,
  loginFailed: null,
  userProfile: {picture: null, sub: '', name: 'John Doe'},
  loggedInUser: null,
  lastUsername: null,
  accessToken: null,
  credentialsRestoreStart: false,
  credentialsRestored: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH0_LOCK_OBJECT':
      return {
        ...state,
        lock: true
      }
    case 'DESTROY_AUTH0_LOCK_OBJECT':
      return {
        ...state,
        lock: null
      }
    case 'LOGIN_START':
      return {
        ...state,
        loginStarted: true,
        loginFailed: null,
        loggedInUser: null
      }
    case 'LOGIN_FAILED':
      return {
        ...state,
        loginStarted: false,
        loginFailed: action.payload.error,
        loggedInUser: null
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loginStarted: false,
        loginFailed: null,
        userProfile: action.payload.profile,
        loggedInUser: action.payload.username,
        lastUsername: action.payload.username,
        accessToken: action.payload.accessToken
      }
    case 'AUTH0_SUCCESS':
      return {
        ...state,
        loginStarted: false,
        loginFailed: null,
        userProfile: action.payload.profile,
        loggedInUser: action.payload.loggedInUser,
        lastUsername: action.payload.loggedInUser,
        accessToken: action.payload.accessToken
      }
    case 'LOGOUT':
      return {
        ...state,
        loginStarted: false,
        loginFailed: false,
        userProfile: null,
        loggedInUser: null,
        lastUsername: state.loggedInUser,
        accessToken: null
      }
    case 'START_RESTORE_CREDENTIALS':
      return {
        ...state,
        credentialsRestoreStart: true,
        credentialsRestored: false
      }
    case 'RESTORE_CREDENTIALS':
      return {
        ...state,
        userProfile: action.payload.profile,
        loggedInUser: action.payload.loggedInUser,
        lastUsername: action.payload.loggedInUser,
        accessToken: action.payload.accessToken,
        credentialsRestored: true,
        credentialsRestoreStart: false
      }
    default:
      return state
  }
}
