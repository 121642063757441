import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, ListItemIcon, ListItemText, FormControlLabel, RadioGroup, Radio, Slider, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreIcon from '@material-ui/icons/MoreVert';
import HelpIcon from '@material-ui/icons/Help';
import TranslateIcon from '@material-ui/icons/Translate';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import eduhkNoTextLogo from '../images/EdUHK_Logo_RGB.png'
import UserAvatar from './UserAvatar'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import styled, { css, keyframes } from 'styled-components'
import './topbar.css'

const RippleLessMenu = ({children, ...props}) => {
  const theme = createMuiTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true
      }
    }
  });
  return (
    <MuiThemeProvider theme={theme}>
        <Menu {...props}>
          {children}
        </Menu>
    </MuiThemeProvider>
  );
}

const StandardMenuItem = ({children, ...props}) => <MenuItem style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} {...props}>{children}</MenuItem>

const slide = keyframes`
    0% {
      left: 50%;

    }
    100% {
      left: 0%;
    }
`;

const appear = keyframes`
    0% {
      opacity: 0;
      transition: opacity 1s ease-in-out;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
    }
    50% {
      opacity: 1;
      transition: opacity 1s ease-in-out;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
    }
    100% {
      opacity: 0;
      transition: opacity 1s ease-in-out;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
    }
`;

const appearAnimation = css`
  ${appear} 6s ease-out infinite
`

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    color: 'black',
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20
  },
  logo: {
    height: 50,
    marginRight: 20
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: 'Noto Serif TC',
    fontWeight: 600,
    fontSize: 36,
    alignSelf: 'center'
  },
  subtitle: {
    marginLeft: 30,
    fontSize: 24,
    alignSelf: 'flex-end'
  },
  moreButton: {
    marginLeft: 10,
    // marginRight: 10
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

function TopBar({ contents, subTitle, loginStarted, signedIn, userProfile, onDrawerClick, showLogin, onSignIn, onSignOut, onHelp, useSimplifiedChinese, onChangeChineseForm, smallScreen, fontSize, onFontSizeChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleProfileMenuClose();
  };

  const classes = useStyles();

  const {title, loginText, cancelLoginText, logoutText, helpText} = contents
  return (
    <AppBar position="fixed">
      <Toolbar className={classes.root} variant="dense" disableGutters={true}>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onDrawerClick}>
          <MenuIcon/>
        </IconButton>
        {!smallScreen && <img src={eduhkNoTextLogo} alt='EdUHK Logo' className={classes.logo}/>}
        <div style={{flex: 1, margin: '0 auto', position: 'relative', ...(smallScreen && {overflow: 'hidden'})}}>
          {(!smallScreen || !subTitle) && <h1 className={subTitle ? 'center active' : 'center'}>
            <span className={subTitle ? 'center active' : 'center'}>
            {title}
            </span>
          </h1>}
          {subTitle && !smallScreen && <span className={'subtitle'}>
            {subTitle}
          </span>}
          {subTitle && smallScreen && <h1 style={{maxWidth: '70%', overflow: 'ellipsis'}}>{subTitle}</h1>}
        </div>
        {signedIn && !loginStarted && !smallScreen && <UserAvatar logoSize='small'/>}

        {/*!signedIn && !showLogin && <Button
          onClick={()=>onSignIn()}
        >
          {loginText}
        </Button>*/}
        {/*!signedIn && showLogin && <MenuItem
          onClick={()=>onSignIn()}>
          <ListItemIcon><CancelIcon/></ListItemIcon>
          <ListItemText primary={cancelLoginText} primaryTypographyProps={{}}/>
        </MenuItem>*/}
        {!signedIn && showLogin && <Button
          variant="contained"
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={onSignIn}
        >
          {cancelLoginText}
        </Button>}
        {!showLogin && <IconButton
          edge="end"
          aria-label="show more"
          aria-controls={'more-menu'}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          className={classes.moreButton}
        >
          <MoreIcon/>
        </IconButton>}
      </Toolbar>
      {!showLogin && <RippleLessMenu
        anchorEl={anchorEl}
        id={'more-menu'}
        keepMounted
        transformOrigin={{ vertical: -60, horizontal: 60 }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <StandardMenuItem>
          <TranslateIcon/>
          <RadioGroup
            aria-label="Text form"
            name="Chinese text form"
            className={classes.group}
            style={{marginLeft: 20}}
            value={useSimplifiedChinese ? "simp" : "trad"}
            onChange={(event) => {
              onChangeChineseForm(event.target.value === 'simp')
              handleMenuClose()
            }}
          >
            <FormControlLabel value="simp" control={<Radio />} label="简体中文" />
            <FormControlLabel value="trad" control={<Radio />} label="正體中文" />
          </RadioGroup>
        </StandardMenuItem>
        <StandardMenuItem>
          <FormatSizeIcon/>
          <Slider
            style={{marginLeft: 10}}
            defaultValue={fontSize}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="off"
            step={1}
            marks
            min={1}
            max={9}
            onChange={(evt, value) => onFontSizeChange(value)}
          />
        </StandardMenuItem>
        <MenuItem onClick={() => {
          onHelp()
          handleMenuClose()
        }}>
          <ListItemIcon><HelpIcon/></ListItemIcon>
          <ListItemText primary={helpText} primaryTypographyProps={{}}/>
        </MenuItem>
        {signedIn && <MenuItem onClick={() => {
          handleMenuClose()
          onSignOut()
        }}>
          <ListItemIcon><DirectionsRunIcon/></ListItemIcon>
          <ListItemText primary={`${logoutText} ${userProfile && userProfile.name}`} primaryTypographyProps={{}}/>
          {!loginStarted && smallScreen && <UserAvatar logoSize='small'/>}
        </MenuItem>}
      </RippleLessMenu>}
    </AppBar>
  )
}

export default TopBar