import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Fade, SnackbarContent } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MuiAlert from '@material-ui/lab/Alert';
import TopBar from './components/TopBar'
import ContentsContainer from './components/ContentsContainer'
import DemoCarousel from './components/DemoCarousel'
import SideMenu from './components/SideMenu'
import Login from './components/Login'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import opencc from 'node-opencc'
import './App.css'
import {
  // initAuth0Login,
  checkSavedSignIn,
  logout
} from './actions/loginActions'
import {
  changeChapter
} from './actions/chaptersActions'

const mapStateToProps = state => ({
  login: state.login,
  chapters: state.chapters
})

const mapDispatchToProps = {
  // initAuth0Login,
  checkSavedSignIn,
  logout,
  changeChapter
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function FadeTransition(props) {
  return <Fade {...props}/>;
}

const styles = theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflow: 'hidden'
  },
  content: {
    padding: 20,
    flexGrow: 1
  }
})

const LogoutConfirmationDialog = ({open, onReply, contents}) => (<Dialog
  open={open}
  onClose={() => onReply(false)}
  aria-labelledby="confirm-dialog-title"
  aria-describedby="confirm-dialog-description"
  fullWidth={true}
  maxWidth={'sm'}
>
  <DialogTitle>{contents.confirmLogoutTitle}</DialogTitle>
  <DialogContent>
    <DialogContentText id="confirm-dialog-description">
      {contents.confirmLogoutText}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => onReply(false)} style={{marginRight: 30}} color="primary" startIcon={<CancelIcon />}>
      {contents.cancelLoginText}
    </Button>
    <Button onClick={() => onReply(true)} color="primary" startIcon={<DirectionsRunIcon />}>
      {contents.logoutText}
    </Button>
  </DialogActions>
</Dialog>)

const AlertDialog = ({open, title, body, buttonText, onClose}) => (<Dialog
  open={open}
  onClose={onClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  fullWidth={true}
  maxWidth={'sm'}
>
  <DialogTitle>{title}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      {body}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={onClose} color="primary" startIcon={<CheckIcon />}>
      {buttonText}
    </Button>
  </DialogActions>
</Dialog>)

// const EmailVerifiedDialog = ({open, onClose}) => (<Dialog
//   open={open}
//   onClose={onClose}
//   aria-labelledby="email-verified-dialog-title"
//   aria-describedby="email-verified-dialog-description"
//   fullWidth={true}
//   maxWidth={'sm'}
// >
//   <DialogTitle>{title}</DialogTitle>
//   <DialogContent>
//     <DialogContentText id="error-dialog-description">
//       {body}
//     </DialogContentText>
//   </DialogContent>
//   <DialogActions>
//     <Button onClick={onClose} color="primary" startIcon={<CheckIcon />}>
//       {buttonText}
//     </Button>
//   </DialogActions>
// </Dialog>)

class App extends React.Component {
  constructor () {
    super()
    this._handleResize = this._handleResize.bind(this)
    this._handleChangeChinese = this._handleChangeChinese.bind(this)
    this._handleFontSizeChange = this._handleFontSizeChange.bind(this)
    this.state = {
      drawerOpened: false,
      confirmLogout: false,
      showLogin: false,
      useSimplifiedChinese: true,
      screenWidth: 0,
      screenHeight: 0,
      fontSize: 3,
      openDemo: false
    }
  }

  componentDidMount() {
    const { bookInfo } = this.props.chapters
    this.props.checkSavedSignIn()
    this._handleResize()
    window.addEventListener("resize", this._handleResize)
    const language = window.localStorage.getItem('eduhk.ssd.language')
    const fontSize = window.localStorage.getItem('eduhk.ssd.fontsize')
    if (language === 'zh-Hant') {
      this.setState({
        useSimplifiedChinese: false
      })
    } else {
      this.setState({
        useSimplifiedChinese: true
      })
    }
    if (typeof fontSize === 'string' && fontSize.match(/^\d$/)) {
      this.setState({
        fontSize: parseInt(fontSize)
      })
    }
    this.setState({
      showTips: true
    })

    if (/^#email_verified$/.exec(window.location.hash)) {
      console.debug(`callback from email verification`)
      window.history.pushState('', document.title, window.location.href.split('#')[0])
      this.setState({
        showAlert: true,
        alertTitle: bookInfo.verifiedEmailTitle,
        alertText: bookInfo.verifiedEmailText
      })
    } else if (/^#password_reset$/.exec(window.location.hash)) {
      console.debug(`callback from password reset`)
      window.history.pushState('', document.title, window.location.href.split('#')[0])
      this.setState({
        showAlert: true,
        alertTitle: bookInfo.passwordResetTitle,
        alertText: bookInfo.passwordResetText
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { bookInfo } = this.props.chapters
    if (!prevProps.login.loginFailed && this.props.login.loginFailed) {
      this.setState({
        showAlert: true,
        alertTitle: this.props.login.loginFailed.match(/^Please verify your email/i) ? bookInfo.verifyEmailTitle : bookInfo.alertText,
        alertText: this.props.login.loginFailed.match(/^Please verify your email/i) ? bookInfo.verifyEmailText : bookInfo.alertTitleText
      })
    }
    if (!prevProps.login.loggedInUser && this.props.login.loggedInUser) {
      this.setState({
        showTips: false
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._handleResize)
  }

  _handleResize() {
    const { innerWidth: screenWidth, innerHeight: screenHeight } = window
    this.setState({
      screenWidth,
      screenHeight
    })
  }

  _handleLogout(answer) {
    answer && this.props.logout()
  }

  _handleChangeChinese(useSimplifiedChinese) {
    window.localStorage.setItem('eduhk.ssd.language', useSimplifiedChinese ? 'zh-Hans' : 'zh-Hant')
    this.setState({
      useSimplifiedChinese
    })
  }

  _handleFontSizeChange(fontSize) {
    window.localStorage.setItem('eduhk.ssd.fontsize', fontSize)
    this.setState({
      fontSize
    })
  }

  _outText(text) {
    if (typeof text === 'string') {
      return text && this.state.useSimplifiedChinese ? opencc.traditionalToSimplified(text) : text
    } else if (text instanceof Array) {
      let outputArray = []
      text.forEach(t => outputArray.push(this._outText(t)))
      return outputArray
    } else if (typeof text === 'object') {
      let output = {}
      Object.keys(text).forEach(k => {
        output[k] = this._outText(text[k])
      })
      return output
    } else {
      return text
    }
  }

  render() {
    const { useSimplifiedChinese, screenWidth, openDemo, fontSize, showTips } = this.state
    const { classes } = this.props
    const { loggedInUser, userProfile, loginStarted, credentialsRestored } = this.props.login
    const { currentChapter, chapterInfo, bookInfo } = this.props.chapters
    const subTitle = chapterInfo.find(c => c.code === currentChapter).subTitle
    const smallScreen = screenWidth < 960
    if (!credentialsRestored) {
      return (
        <Paper className={classes.root} elevation={1}>
          <CircularProgress size={100}/>
        </Paper>
      )
    }

    return (
      <Paper className={classes.root} elevation={1}>
        <TopBar
          smallScreen={smallScreen}
          contents={this._outText(bookInfo)}
          subTitle={this._outText(subTitle)}
          signedIn={!!loggedInUser}
          loginStarted={loginStarted}
          userProfile={userProfile}
          onDrawerClick={() => this.setState({
            drawerOpened: !this.state.drawerOpened
          })}
          onSignOut={() => {this.setState({confirmLogout: true})}}
          onSignIn={() => this.setState({showLogin: !this.state.showLogin})}
          showLogin={this.state.showLogin}
          onHelp={() => this.setState({openDemo: true})}
          useSimplifiedChinese={useSimplifiedChinese}
          onChangeChineseForm={this._handleChangeChinese}
          fontSize={fontSize}
          onFontSizeChange={this._handleFontSizeChange}
        />
        <SideMenu
          smallScreen={smallScreen}
          open={this.state.drawerOpened}
          currentChapter={currentChapter}
          bookInfo={this._outText(bookInfo)}
          chapterInfo={this._outText(chapterInfo)}
          onClose={() => this.setState({drawerOpened: false})}
          onSelect={(selectedChapter) => this.props.changeChapter(selectedChapter)}
          loggedIn={!!loggedInUser}
        />
        {!this.state.showLogin && <div style={{flex: 1, paddingTop: 70, width: '100vw'}}>
          <ContentsContainer
            fontSize={fontSize}
            smallScreen={smallScreen}
            bookInfo={this._outText(bookInfo)}
            useSimplifiedChinese={useSimplifiedChinese}
            signedIn={!!loggedInUser}
            onSignIn={() => this.setState({showLogin: !this.state.showLogin})}
            onClickShowTips={() => this.setState({showTips: true})}
          />
          </div>}
        {/*!loggedInUser && !this.state.showLogin && <Intro bookInfo={this._outText(bookInfo)}/>*/}
        <Login show={!loggedInUser && this.state.showLogin} useSimplifiedChinese={useSimplifiedChinese}/>
        {this.state.confirmLogout && <LogoutConfirmationDialog
          open={this.state.confirmLogout}
          contents={this._outText(bookInfo)}
          onReply={(answer) => {
            this.setState({
              confirmLogout: false
            }, () => this._handleLogout(answer))
          }}
        />}
        {this.state.showAlert && <AlertDialog
          open={this.state.showAlert}
          title={this._outText(this.state.alertTitle)}
          body={this._outText(this.state.alertText)}
          buttonText={this._outText(bookInfo.confirmButtonText)}
          onClose={() => {
            this.setState({
              showAlert: false,
              alertTitle: null,
              alertText: null
            })
          }}
        />}
        <DemoCarousel
          smallScreen={smallScreen}
          useSimplifiedChinese={useSimplifiedChinese}
          open={openDemo}
          onClose={() => this.setState({openDemo: false})}
          onStart={() => this.setState({openDemo: false})}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showTips}
          autoHideDuration={6000}
          onClose={() => this.setState({showTips: false})}
          TransitionComponent={FadeTransition}
          message="hello world"
        >
          <Alert onClose={() => this.setState({showTips: false})} severity="info">
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {this._outText(bookInfo.tocNoteText1)}
              <MenuIcon/>
              {this._outText(bookInfo.tocNoteText2)}
            </div>
            {this._outText(bookInfo.tocNoteText3)}
          </Alert>
        </Snackbar>
      </Paper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App))
