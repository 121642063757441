import chapterInfo from '../resources/chapterInfo'
import bookInfo from '../resources/bookInfo'

const initialState = {
  currentChapter: 'authors',
  chapterInfo,
  bookInfo
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_CHAPTER':
      return {
        ...state,
        currentChapter: action.payload.chapter
      }
    case 'LOGOUT':
      return initialState
    default:
      return state
  }
}

