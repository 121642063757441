import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Auth0Lock } from 'auth0-lock';
import config from '../resources/config'
import {
  // initAuth0Login,
  loginAuth0,
  destryoAuth0Lock
} from '../actions/loginActions'

const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = {
  // initAuth0Login,
  loginAuth0,
  destryoAuth0Lock
}

const { auth0Config } = config

const options = {
  // popupOptions: { width: 300, height: 400, left: 200, top: 300 },
  container: 'auth0-container',
  languageDictionary: {
    title: ' ',
    enterpriseLoginIntructions: ' '
  },
  rememberLastLogin: false,
  theme: {
    primaryColor: "#888"
  },
  // auth: {
  //   redirect: false
  // },
  language: 'zh-tw',
  loginAfterSignup: false,
  // mustAcceptTerms: false,
  autoParseHash: true
}
// const options = {
//    auth: {
//     redirectUrl: 'https://161.117.230.66/',
//     responseType: 'code',
//     params: {
//       scope: 'openid profile email'
//     }
//   }
// }

const optionsSimpChinese = {
  ...options,
  language: 'zh'
}

let lock = null

const styles = theme => ({
  root: {
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 90,
    position: 'absolute',
    zIndex: -1000,
    pointerEvents: 'none'
  },
  show: {
    position: 'static',
    zIndex: 'auto',
    pointerEvents: 'auto'
  },
  // hide: {
  //   height: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   // visibility: 'hidden'
  // },
  auth0: {
    width: 480,
    height: 100,
    zoom: '100%',
    justifyContent:
    'center'
  }
})

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    const { useSimplifiedChinese } = this.props
    if (!lock) {
      lock = new Auth0Lock(auth0Config.clientId, auth0Config.domain, useSimplifiedChinese ? optionsSimpChinese : options)
      !this.props.login.loggedInUser && this.props.loginAuth0(lock, useSimplifiedChinese ? optionsSimpChinese : options)
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const { useSimplifiedChinese } = this.props
    if (prevProps.useSimplifiedChinese !== this.props.useSimplifiedChinese) {
      lock = new Auth0Lock(auth0Config.clientId, auth0Config.domain, useSimplifiedChinese ? optionsSimpChinese : options)
    }
    if (!prevProps.login.credentialsRestored && this.props.login.credentialsRestored && lock) {
      // lock = new Auth0Lock(auth0Config.clientId, auth0Config.domain, options)
      this.props.loginAuth0(lock, useSimplifiedChinese ? optionsSimpChinese : options)
    }
    if (lock && !prevProps.show && this.props.show) {
      lock.show()
    } else if (lock && prevProps.show && !this.props.show) {
      lock.hide()
    }
  }

  componentWillUnmount() {
    lock = null
    // this.props.login.lock && this.props.destryoAuth0Lock()
  }

  // _handleSignIn() {
  //   // this.props.initAuth0Login()
  //   !this.props.login.lock && this.props.loginAuth0()
  // }

  render() {
    const { classes, show } = this.props

    return (
      <div className={show ? `${classes.root} ${classes.show}` : classes.root}>
        <div id='auth0-container' className={classes.auth0}>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
