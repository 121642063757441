import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import eduhkLogo from '../images/EdUHK-logo.png'
import crllsLogo from '../images/crlls-logo.jpg'
import humanitiesLogo from '../images/humanities-logo.svg'
import lmlLogo from '../images/lml-logo.png'
import { blue, yellow } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // paddingTop: '5vh'
    // minHeight: 400
  },
  textDiv: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  eduLogo: {
    height: 80,
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 20
  },
  eduLogoSmall: {
    height: 60,
    marginRight: 6,
    marginLeft: 6,
    marginBottom: 10
  },
  fhmLogo: {
    height: 56,
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 20
  },
  fhmLogoSmall: {
    height: 45,
    marginRight: 6,
    marginLeft: 6,
    marginBottom: 10
  },
  crllsLogo: {
    height: 80,
    marginRight: 15,
    marginLeft: 20,
    marginBottom: 20
  },
  crllsLogoSmall: {
    height: 68,
    marginRight: 4,
    marginLeft: 5,
    marginBottom: 10
  },
  lmlLogo: {
    height: 52,
    marginRight: 10,
    marginLeft: 8,
    marginBottom: 20
  },
  lmlLogoSmall: {
    height: 42,
    marginRight: 6,
    marginLeft: 6,
    marginBottom: 10
  },
  logoRow: {
    display: 'flex',
    // flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '80vw',
    marginTop: '5vh'
  },
  loggedInLogoRow: {
    marginTop: '15vh'
  },
  authorContentDiv: {
    marginTop: 40
  },
  introContentDiv: {
    marginTop: 40
  },
  button: {
    width: '100%',
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tipsText: {
    marginLeft: 5,
    fontSize: 'small',
    color: blue[400],
    // backgroundColor: yellow[300],
    // textDecoration: 'underline',
    padding: 5,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    transform: 'translateY(-15px)'
    // verticalAlign: 'top'
  }
})

class Intro extends React.Component {
  constructor () {
    super()
    this.state = {
    }
  }

  render() {
    const { classes, bookInfo, fontSize, smallScreen, signedIn, onSignIn, onClickShowTips } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.textDiv}>
          <div className={classes.authorContentDiv}>
            <Typography variant="h1" style={{fontSize: `${5 + fontSize * 0.4}vmin`}}>{bookInfo.authorList}</Typography>
          </div>
          <div className={classes.introContentDiv}>
            {bookInfo.introLines.map((line, index) => <Typography key={index} variant="h2" style={{marginTop: 10, fontSize: `${4 + fontSize * 0.4}vmin`}}>{line}</Typography>)}
          </div>
          {!signedIn && <div className={classes.button}>
            <div style={{flex: 1}}>
            </div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PeopleIcon />}
              onClick={onSignIn}
              size="large"
              style={{fontSize: `${2 + fontSize * 0.2}vmin`}}
            >
              {bookInfo.loginText}
            </Button>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start'}}>
              <span className={classes.tipsText} onClick={onClickShowTips}>
                <ContactSupportIcon/>
                {bookInfo.showTipsText}
              </span>
            </div>
          </div>}
        </div>
        <div className={signedIn ? `${classes.logoRow} ${classes.loggedInLogoRow}` : classes.logoRow}>
          <a href="https://www.eduhk.hk/"><img src={eduhkLogo} alt='EdUHK Logo' className={smallScreen ? classes.eduLogoSmall : classes.eduLogo}/></a>
          <a href="https://www.eduhk.hk/fhm/"><img src={humanitiesLogo} alt='Faculty of Humanities Logo' className={smallScreen ? classes.fhmLogoSmall : classes.fhmLogo}/></a>
          <a href="https://www.eduhk.hk/crlls/"><img src={crllsLogo} alt='Centre for Research on Linguistics and Language Studies Logo' className={smallScreen ? classes.crllsLogoSmall : classes.crllsLogo}/></a>
          <a href="https://www.eduhk.hk/lml/"><img src={lmlLogo} alt='Linguistics and Modern Languages Logo' className={smallScreen ? classes.lmlLogoSmall : classes.lmlLogo}/></a>
        </div>
      </div>)
  }
}

export default (withStyles(styles)(Intro))