import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Drawer, IconButton, List, Divider, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DoneIcon from '@material-ui/icons/Done';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import eduhkNoTextLogo from '../images/EdUHK_Logo_RGB.png'

const styles = theme => ({
  list: {
    width: 360,
    cursor: 'pointer',
    overflow: 'hidden',
    zIndex: 100,
    paddingTop: 0,
    paddingBottom: 100
  },
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch'
  },
  logo: {
    height: 36,
    marginRight: 5
  },
  content: {
    padding: 20,
    flexGrow: 1
  },
  bookTitle: {
    fontWeight: 900,
    // backgroundColor: grey[200],
    fontSize: 'larger'
  },
  mainItem: {
    fontWeight: 700,
    fontSize: 'large',
    textAlign: 'center'
  },
  chapterItem: {
    fontWeight: 600,
    fontSize: 'large'
  },
  menuItem: {
    '&:hover': {
      backgroundColor: orange[200]
    }
  },
  menuItemDisabled: {
    color: grey[400],
    '&:hover': {
      backgroundColor: orange[200]
    }
  },
  selectedMenuItem: {
    backgroundColor: 'cyan'
  }
})

class SideMenu extends React.Component {
  constructor () {
    super()
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes, open, currentChapter, bookInfo, chapterInfo, loggedIn, onClose, onSelect } = this.props
    console.debug(`in SideMenu, loggedIn = ${loggedIn}`)
    console.debug(`in SideMenu, chapterInfo = ${JSON.stringify(chapterInfo)}`)
    return (
      <Drawer open={open} onClose={onClose}>
        <div role="presentation">
          <List className={classes.list}>
            <ListItem
              style={{paddingLeft: 20, backgroundColor: grey[200]}}
            >
              <ListItemIcon>
                <IconButton
                  aria-label="close menu"
                  aria-haspopup="true"
                  onClick={onClose}
                  color="inherit"
                >
                  <MenuOpenIcon/>
                </IconButton>
              </ListItemIcon>
              {<img src={eduhkNoTextLogo} alt='EdUHK Logo' className={classes.logo}/>}
              <ListItemText primary={bookInfo.title} primaryTypographyProps={{className: classes.bookTitle}}/>
            </ListItem>
            {/*<ListItem
              style={{paddingLeft: 20}}
            >
              <ListItemText primary={bookInfo.tocText} primaryTypographyProps={{className: classes.mainItem}}/>
            </ListItem>*/}
            <Divider/>
            {chapterInfo.map((item, index) => 
              <ListItem
                key={`${index}-${item.name}`}
                style={{paddingLeft: 20}}
                className={item.restricted && !loggedIn ? classes.menuItemDisabled : classes.menuItem}
                onClick={() => {
                  if (loggedIn || !item.restricted) {
                    console.debug(`selected item: ${item.code}`)
                    onSelect(item.code)
                    onClose()
                  }
                }}
              >
                <ListItemText primary={item.name} primaryTypographyProps={{className: classes.chapterItem}}/>
                {loggedIn && item.code === currentChapter && <ListItemSecondaryAction>
                  <DoneIcon />
                </ListItemSecondaryAction>}
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    )
  }
}

export default (withStyles(styles)(SideMenu))