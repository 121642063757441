import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core'

const mapStateToProps = state => ({
  login: state.login
})

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
})

class UserAvatar extends React.Component {
  constructor () {
    super()
    this.state = {
    }
  }

  render() {
    const { classes, logoPosition, logoSize } = this.props
    const { userProfile } = this.props.login
    const { name, picture } = userProfile
    return (
      <div className={classes.root}>
        {logoPosition === 'right' && <span>{name}</span>}
        <Avatar
          alt={name}
          src={picture}
          className={logoSize === 'small' ? classes.small : classes.large}
        />
        {logoPosition === 'left' && <span>{name}</span>}
      </div>)
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(UserAvatar))