"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  colors: {
    primary: '#00000096',
    secondary: '#00000069',
    tertiary: '#00000085',
    quaternary: '#000000',
    quinary: '#000000'
  }
};
exports.default = _default;