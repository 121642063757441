const bookInfo = {
  showTipsText: '說明',
  tocNoteText1: '點擊左上角圖示「',
  tocNoteText2: '」進入目錄',
  tocNoteText3: '請先登入以獲取內容',
  title: '普通話語音分析手冊',
  tocText: '目錄',
  loginText: '註冊/登入',
  cancelLoginText: '取消',
  logoutText: '登出',
  helpText: '幫助',
  confirmLogoutText: '是否確認登出？',
  confirmLogoutTitle: '確認登出',
  errorLoginText: '登入',
  errorButtonText: '確認',
  confirmButtonText: '確認',
  errorLoginTitleText: '登入錯誤。如有困難，請跟我們聯絡。',
  verifiedEmailTitle: '電郵地址成功確認',
  verifiedEmailText: '你現可按下「註冊/登入」，使用你的註冊電郵地址和密碼登入。',
  passwordResetTitle: '重設密碼成功',
  passwordResetText: '重設密碼成功，請使用新的密碼登入。',
  verifyEmailTitle: '確認電郵地址',
  verifyEmailText: '請檢查郵件，按下連結以作確認。',
  authorList: '張顯達　李烱樂',
  introLines: [
    '香港教育大學',
    '語言學及現代語言系',
    '2020年'
  ]
}

export default bookInfo